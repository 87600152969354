import React, { useState, useEffect } from "react"
import { ResponsiveChoropleth } from '@nivo/geo'
// import loadable from '@loadable/component'
import regions from "../../data/maps/r17.json"

// const ResponsiveChoropleth = loadable(() => import('@nivo/geo'), {
//     resolveComponent: (components) => components.ResponsiveChoropleth,
//   })

// const regions = loadable(() => import('../../data/maps/r17.json'));

const RegionMap = ({data, category,color,range}) =>{
    const [windowSize, setWindowSize] = useState(undefined)
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize(window.innerWidth);
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    let map = regions.features
    // add id to the map
    map.forEach((region)=>{
        region.id = region.properties.REGION
    })
    // add node name to the map
    data.forEach((node)=>{
        const regionIndex = map.findIndex((region)=>{
            return region.properties.REGION === node.region;
        });
        map[regionIndex].properties.name = node.name;
    });
    
    const vpnData = data.map((node)=>{
        return {
            "id": node.region,
            "value": node[category]
    }})

    const nivoReverse = ['#48BCB5','#7CD5C4','#A9E8DC','#EBCAAF','#F78B79','#DD7C63']
    const colors = [nivoReverse, "nivo"]

    return(
        <div className="map-region">
            <ResponsiveChoropleth
                data={vpnData}
                features={map}
                margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                colors={colors[color]}
                domain={range}
                unknownColor={"#f5f5f5"}
                label="properties.name"
                valueFormat=".2s"
                projectionScale={windowSize > 578 ? 100: 70}
                projectionTranslation={[ 0.5, 0.65 ]}
                projectionRotation={[ 0, 0, 0 ]}
                enableGraticule={true}
                graticuleLineColor="#dddddd"
                borderWidth={0.5}
                borderColor="#152538"
                legends={[
                    {
                        anchor: 'bottom-left',
                        direction: 'column',
                        justify: true,
                        translateX: 0,
                        translateY: -10,
                        itemsSpacing: 0,
                        itemWidth: 72,
                        itemHeight: 16,
                        itemDirection: 'left-to-right',
                        itemTextColor: '#444444',
                        itemOpacity: 0.85,
                        symbolSize: 16,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000000',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    )

}

export default RegionMap